import React from 'react';
import { Layout } from '../../../components';
import EventCreate from '../../../components/EventCreate';

const CreateEvent = () => {
  return (
    <Layout>
      <div className="lg:w-10/12 mx-auto">
        <EventCreate noCancel />
      </div>
    </Layout>
  );
};

export default CreateEvent;
